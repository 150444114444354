import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { getPairKey, usePriceContext } from "@contexts/price";
import { useMedia } from "@hooks/useMedia";
import { useStoredState } from "@hooks/useStoredState";
import { ProportionBar } from "@molecules/ProportionBar";
import { Statistic } from "@molecules/Statistic";
import { Tooltip } from "@molecules/Tooltip";
import { TRADE_HEADER_HEIGHT } from "@screens/Trade";
import { formatPrice } from "@utils";
import { Pair } from "gql/graphql";
import { memo, useMemo } from "react";
import { Pressable } from "react-native";
import { useTheme } from "styled-components/native";
import { IPerformance } from "../../../usePerformance";
import { MIN_WIDTH } from "../../List/utils";

export const PriceChange = memo(
    ({
        pair,
        loading,
        performance,
    }: {
        loading: boolean;
        pair?: Pair;
        performance?: IPerformance | null;
    }) => {
        const theme = useTheme();
        const media = useMedia();
        const { prices } = usePriceContext();

        const [showPercentage, setShowPercentage] = useStoredState(
            true,
            "PRICE_CHANGE_SHOW_PERCENTAGE",
            true
        );

        const togglePercentage = () => {
            setShowPercentage(!showPercentage);
        };

        const itemWidth = media.isMobile ? "auto" : MIN_WIDTH;

        const price = useMemo(() => {
            if (!pair) return null;
            if (!prices) return null;
            return prices.get(getPairKey(pair))?.mid || null;
        }, [pair, prices]);

        const priceDifference = useMemo(() => {
            if (price != null && performance?.price24hAgo != null && !loading)
                return price - performance?.price24hAgo;
            return 0;
        }, [loading, performance?.price24hAgo, price]);

        const priceChange = useMemo(() => {
            if (price != null && performance?.price24hAgo != null && !loading)
                return (100 * priceDifference) / price;
            return 0;
        }, [loading, performance?.price24hAgo, price, priceDifference]);

        const formatted24hPerformance = useMemo(() => {
            if (showPercentage)
                return `${priceChange > 0 ? "+" : ""}${formatPrice(
                    priceChange,
                    {
                        decimals: 2,
                        currency: false,
                    }
                )}%`;

            if (price)
                return `${formatPrice(priceDifference, {
                    decimals: 2,
                })}`;
            return "";
        }, [showPercentage, priceChange, price, priceDifference]);

        return (
            <Pressable
                onPress={togglePercentage}
                style={{
                    width: "100%",
                }}
            >
                <Tooltip
                    title="24h Change"
                    content="Price range in the last 24 hours"
                    minWidth
                    disabled={
                        (!price && loading) || performance?.price24hAgo == null
                    }
                    position="bottom-left"
                    renderContent={
                        <Box>
                            <Flex justify="space-between">
                                <Text
                                    smaller
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        0.6
                                    )}
                                >
                                    Lowest
                                </Text>
                                <Text
                                    smaller
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        0.6
                                    )}
                                >
                                    Highest
                                </Text>
                            </Flex>

                            <Flex
                                justify="space-between"
                                style={{
                                    paddingVertical: theme.spacing.small,
                                }}
                            >
                                <Text smaller mono bold>
                                    {formatPrice(performance?.lowest, {
                                        decimals: 2,
                                        currency: false,
                                    })}
                                </Text>
                                <Text smaller mono bold>
                                    {formatPrice(
                                        price &&
                                            performance?.highest &&
                                            price > performance?.highest
                                            ? price
                                            : performance?.highest,
                                        {
                                            decimals: 2,
                                            currency: false,
                                        }
                                    )}
                                </Text>
                            </Flex>
                            <Box>
                                <ProportionBar
                                    line
                                    gradient={[
                                        theme.color.green,
                                        theme.color.red,
                                    ]}
                                    min={
                                        performance?.lowest
                                            ? performance?.lowest
                                            : 0
                                    }
                                    max={
                                        performance?.highest
                                            ? performance?.highest
                                            : 0
                                    }
                                    value={price || 0}
                                />
                            </Box>
                        </Box>
                    }
                    style={{
                        height: "100%",
                        justifyContent: "center",
                        minWidth: itemWidth,
                        paddingHorizontal: theme.spacing.bigger,
                    }}
                >
                    <Flex
                        align="center"
                        style={{
                            height: TRADE_HEADER_HEIGHT,
                        }}
                        gap={theme.spacing.bigger}
                    >
                        <Statistic
                            small
                            label="24h Change"
                            mono={false}
                            value={formatted24hPerformance}
                            red={priceChange < 0}
                            green={priceChange > 0}
                            loading={
                                (!price && loading) ||
                                performance?.price24hAgo == null
                            }
                            style={{
                                gap: theme.spacing.smaller,
                            }}
                        />
                    </Flex>
                </Tooltip>
            </Pressable>
        );
    }
);
