import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { useNotificationContext } from "@contexts/notification";
import { Button } from "@molecules/Button";
import { CurrencyInputRef, Input, InputRef } from "@molecules/Input";
import { Tooltip } from "@molecules/Tooltip";
import { useIdentityToken } from "@privy-io/react-auth";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Pressable } from "react-native";
import { useTheme } from "styled-components/native";

import { generateUsername } from "unique-username-generator";
export const EditUsername = memo(() => {
    const theme = useTheme();
    const { address } = useOstiumAccount();
    const { identityToken } = useIdentityToken();
    const { userFE, refetchUser, setShowUsernameModal } = useAppContext();
    const [username, setUsername] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { notify } = useNotificationContext();

    const isValid = useMemo(() => {
        if (!username || username.length < 3) return true;
        const res =
            /^(?!.*[ ]{2})(?!.*[.]{2})(?!.*[_]{2})(?!.*[-]{2})[a-zA-Z0-9_ .-]{3,25}$/.exec(
                username
            );
        return res;
    }, [username]);

    const onSubmit = useCallback(async () => {
        try {
            if (!address) return;
            setLoading(true);
            const params = JSON.stringify({
                address: address.toLowerCase(),
                username,
                userWalletAddress: address.toLowerCase(),
            });
            const response = await fetch(`/api/user/update-username`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json", /// Ensure the request is sent with the correct content type
                    ...(identityToken
                        ? { "privy-id-token": identityToken }
                        : {}),
                },
                body: params,
            }).then((res) => res.json());

            if (response.error) {
                setError(response.error);
                setLoading(false);
                return;
            }
            refetchUser();
            setLoading(false);
            setShowUsernameModal(false);
            notify({
                title: "Username updated",
                description: "Username updated successfully!",
                type: "Success",
            });
        } catch (err) {
            setLoading(false);
            console.log("responseresponseresponseerr", err);
            console.log(err);
        }
    }, [
        address,
        refetchUser,
        username,
        setError,
        setLoading,
        identityToken,
        notify,
        setShowUsernameModal,
    ]);

    const inputRef = useRef<InputRef>();

    // const onRemove = useCallback(async () => {
    //     try {
    //         if (!address) return;
    //         const params = new URLSearchParams({
    //             address: address,
    //         });
    //         await fetch(`/api/user/delete-username`, {
    //             method: "POST",
    //             body: params,
    //         });
    //         await refetchUser();
    //         setUsername("");
    //         inputRef.current?.input?.focus();
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }, [address, refetchUser]);

    // const onRegenerate = useCallback(async () => {
    //     try {
    //         if (!address) return;
    //         const generatedUsername = generateUsername(" ", undefined, 25);
    //         const params = new URLSearchParams({
    //             username: generatedUsername,
    //         });
    //         const result = await fetch(
    //             `/api/user/available-username?${params}`
    //         ).then((res) => res.json());
    //         if (result) {
    //             inputRef.current?.input?.focus();
    //             setUsername(generatedUsername);
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }, [address]);

    useEffect(() => {
        if (userFE?.username) setUsername(userFE.username);
    }, [userFE?.username]);

    const buttonProps = useMemo(() => {
        if (userFE?.username === username) {
            return {
                text: "Update",
                stroke: true,
                noBorder: true,
                disabled: true,
            };
        }
        if (!username?.length)
            return {
                text: "Update",
                stroke: true,
                noBorder: true,
                disabled: true,
            };
        if (username.length < 3)
            return {
                text: "Update",
                primary: true,
                disabled: true,
            };
        if (username.length > 25)
            return {
                text: "Update",
                primary: true,
                disabled: true,
            };

        return {
            text: "Update",
            primary: true,
            disabled: !username || loading,
            onPress: onSubmit,
        };
    }, [onSubmit, userFE?.username, username, loading]);

    return (
        <Box
            style={{
                padding: theme.spacing.bigger,
                paddingTop: 0,
            }}
            gap={theme.spacing.biggest}
        >
            <Box
                style={{
                    height: 0.6,
                    marginTop: 6,
                    backgroundColor: "rgba(217, 217, 217, 0.10)",
                }}
            ></Box>

            <Box>
                <Flex style={{ gap: 4, paddingBottom: 10 }}>
                    <Text smallest style={{ color: "#9AA1A1" }}>
                        Username
                    </Text>{" "}
                    <Tooltip
                        {...{
                            title: "Set username",
                            content:
                                "Username must be unique and between 3 and 25 characters long and can only contain lowercase letters, numbers, underscores, and periods.",
                            top: 10,
                            position: "bottom-left",
                            minWidth: 270,
                        }}
                    >
                        <Box
                            style={{
                                height: 13,
                                width: 13,
                            }}
                            align="center"
                            justify="center"
                        >
                            <Icon
                                name="question"
                                color={theme.color.rgba(theme.color.white, 0.6)}
                                size={10}
                            />
                        </Box>
                    </Tooltip>
                </Flex>
                {/* @ts-ignore */}
                <Input
                    type="text"
                    ref={inputRef}
                    value={username}
                    error={!isValid ? "Invalid username" : undefined}
                    // @ts-ignore
                    placeholder={userFE?.username}
                    placeholderTextColor={theme.color.rgba(
                        theme.color.white,
                        0.7
                    )}
                    onChangeText={(text) => {
                        if (error.length) setError("");
                        setUsername(text);
                    }}
                    renderRight={
                        <Flex gap={theme.spacing.smaller}>
                            <Pressable>
                                <Text
                                    smaller
                                    semiBold
                                    color={theme.color.greyish}
                                >
                                    {username.length}/25
                                </Text>
                            </Pressable>
                        </Flex>
                    }
                />

                <Text
                    smallest
                    style={{ color: theme.color.red, paddingTop: 10 }}
                >
                    {error}
                </Text>
            </Box>
            {/* <Text
                smaller
                lineHeight={theme.text.big}
                color={theme.color.rgba(theme.color.white, 0.7)}
            >
                Username must be between 3 and 25 characters long and can only
                contain lowercase letters, numbers, underscores, and periods.
            </Text> */}
            <Button loading={loading} {...buttonProps} />
        </Box>
    );
});
