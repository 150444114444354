import { useQuery, gql } from "@hooks/useApollo";
import { useWeb3Context } from "@contexts/web3";

import { DropResult } from "@hello-pangea/dnd";
import { useStoredState } from "@hooks/useStoredState";
import { SelectOptionType } from "@molecules/Select";
import { GET_USER } from "@screens/Portfolio/Positions/PortfolioStatistics";
import { Operation, PositionOrderTypes, Timeframe } from "@screens/Trade/utils";
import { Limit, Order, Pair, Trade, User } from "gql/graphql";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { IListColumn } from "../../../../theme/components/organisms/List/Column";
import { IListTabOption } from "./Tabs";
import { Sort } from "./Template/context";
import { Column, HISTORY_COLUMNS, ORDER_COLUMNS, TRADE_COLUMNS } from "./utils";
import { PRECISION_2 } from "@ostium/formulae/src";

import { BigNumber } from "@utils";

export enum Tab {
    TRADES = "TRADES",
    ORDERS = "ORDERS",
    HISTORY = "HISTORY",
    TRADERS = "TRADERS",
    MOST_TRADES = "MOST_TRADES",
}

interface IColumns {
    [key: string]: IListColumn[];
}

export const HISTORY_TIMEFRAMES: SelectOptionType[] = [
    {
        text: "1 day",
        value: Timeframe.Daily,
    },
    {
        text: "1 week",
        value: Timeframe.Weekly,
    },
    {
        text: "1 month",
        value: Timeframe.Monthly,
    },
    {
        text: "3 months",
        value: Timeframe.Yearly,
    },
];

export const OPERATIONS: SelectOptionType[] = [
    {
        text: "All",
        value: Operation.All,
    },

    {
        text: "Open",
        value: Operation.Open,
    },
    {
        text: "Close",
        value: Operation.Close,
    },
    {
        text: "TakeProfit",
        value: Operation.TakeProfit,
    },
    {
        text: "StopLoss",
        value: Operation.StopLoss,
    },
    {
        text: "Liquidation",
        value: Operation.Liquidation,
    },
    {
        text: "Cancelled",
        value: Operation.Cancelled,
    },
];

const TradeListContext = createContext(
    {} as {
        pair?: Pair;
        pairs: { [key: string]: Pair };

        tabs: IListTabOption[];
        showAllPairs: boolean;
        isCollapsed?: boolean;
        toggleShowAllPairs: () => void;
        toggleCollapsed?: () => void;
        timeframe: SelectOptionType;
        operation: SelectOptionType;

        // data
        userData?: User;
        tradesData: Trade[];
        ordersData: (Limit | Order)[];
        historyData: Order[];
        isLoadingTrades: boolean;
        isLoadingOrders: boolean;
        isLoadingHistory: boolean;

        // columns
        initialColumns: IColumns;
        onChangeOperation: (value: SelectOptionType) => void;
        onChangeTimeframe: (value: SelectOptionType) => void;
        onChangeSort: (value: IListColumn) => void;
        onToggleColumn: (column: IListColumn) => void;
        onResetColumns: () => void;
        onUpdateColumn: (result: DropResult) => void;
        onChangeTab: (id: string) => void;
        currentTab: string | null;
    }
);

// @ts-ignore
const DEFAULT_SORT: { [key in Tab]: Sort } = {
    [Tab.TRADES]: {
        by: null,
        direction: "desc",
        tab: Tab.TRADES,
    },
    [Tab.ORDERS]: {
        by: Column.TIME,
        direction: "desc",
        tab: Tab.ORDERS,
    },
    [Tab.HISTORY]: {
        by: Column.TIME,
        direction: "desc",
        tab: Tab.HISTORY,
    },
};

function useTradeList({
    isCollapsed,
    pair,
    pairs,
    address,
    toggleCollapsed,
}: {
    address?: `0x${string}`;
    pair: Pair | null;
    pairs: Map<string, Pair>;
    isCollapsed?: boolean;
    toggleCollapsed?: () => void;
}) {
    const { storageContract } = useWeb3Context();

    const [currentTab, setCurrentTab] = useState<string | null>(null);
    const [ordersData, setOrdersData] = useState<(Limit | Order)[]>([]);
    const [isLoadingOrders, setIsLoadingOrders] = useState(
        address ? true : false
    );

    const [showAllPairs, setShowAllPairs] = useStoredState(
        true,
        "SHOW_ALL_PAIRS"
    );

    const [timeframe, setTimeframe] = useStoredState<SelectOptionType>(
        HISTORY_TIMEFRAMES[1],
        "HISTORY_TIMEFRAME",
        HISTORY_TIMEFRAMES[1]
    );
    const [operation, setOperation] = useStoredState<SelectOptionType>(
        OPERATIONS[0],
        "HISTORY_OPERATIONS",
        OPERATIONS[0]
    );

    const initialColumns: IColumns = useMemo(() => {
        return {
            [Tab.TRADES]: TRADE_COLUMNS,
            [Tab.ORDERS]: ORDER_COLUMNS,
            [Tab.HISTORY]: HISTORY_COLUMNS,
        };
    }, []);

    const getMarketOrders = useCallback(
        async (item: any) => {
            try {
                const result = await storageContract?.reqID_pendingMarketOrder(
                    item.id.split("_")[0]
                );

                // Check if result exists and has required properties
                if (result && result.trade && result.wantedPrice) {
                    const { trade, wantedPrice } = result;
                    return {
                        ...item,
                        leverage: trade.leverage.toString(),
                        collateral: trade.collateral.toString(),
                        stopLossPrice: trade.sl.toString(),
                        takeProfitPrice: trade.tp.toString(),
                        isBuy: trade.buy,
                        price: wantedPrice.toString(),
                        notional: BigNumber.from(trade.collateral)
                            .mul(trade.leverage)
                            .div(PRECISION_2)
                            .toString(),
                        orderType: PositionOrderTypes.Market,
                    } as Order;
                }
                return null;
            } catch (err) {
                console.warn(err);
                return null;
            }
        },
        [storageContract]
    );

    const onChangeTab = useCallback((id: string) => {
        setCurrentTab(id);
    }, []);

    const { data: tradesData, loading: isLoadingTrades } = useQuery(
        GET_TRADES,
        {
            variables: {
                trader: address?.toLowerCase(),
            },
            skip: !address,
            pollInterval: 5000,
            fetchPolicy: "cache-and-network",
        }
    );

    const parseOrders = useCallback(
        async (data: { limits: Limit[]; orders: Order[] }) => {
            try {
                setIsLoadingOrders(true);

                //filter out orders where order.orderAction === 'Close' && tradeID is not in tradesData
                const filtersOrders = data.orders.filter(
                    (order) =>
                        order.orderAction !== "Close" ||
                        tradesData?.trades.some(
                            (trade: Trade) =>
                                Number(trade.id) === Number(order.tradeID)
                        )
                );
                let results = await Promise.all(
                    filtersOrders.map(getMarketOrders)
                );
                results = results.filter((item) => item);

                const unique = new Map(
                    [...data.limits, ...(results as Order[])].map((item) => [
                        item.id,
                        item,
                    ])
                );

                setOrdersData([...unique.values()]);
                setIsLoadingOrders(false);
            } catch (err) {
                console.warn(err);
                setIsLoadingOrders(false);
            }
        },
        [getMarketOrders, tradesData?.trades]
    );

    useEffect(() => {
        if (!address && ordersData?.length) {
            setIsLoadingOrders(false);
            setOrdersData([]);
        }
    }, [address, ordersData?.length]);

    const { data: userData } = useQuery(GET_USER, {
        variables: {
            id: address,
        },
        skip: !address,
        pollInterval: 5000,
        fetchPolicy: "cache-and-network",
    });

    useQuery(GET_ORDERS, {
        variables: {
            trader: address?.toLowerCase(),
        },
        skip: !address,
        pollInterval: 5000,
        fetchPolicy: "cache-and-network",
        onCompleted: parseOrders,
        notifyOnNetworkStatusChange: true,
    });

    const historyVariables = useMemo(() => {
        const now = new Date();
        const days =
            timeframe.value === Timeframe.Daily
                ? 1
                : timeframe.value === Timeframe.Weekly
                ? 7
                : timeframe.value === Timeframe.Monthly
                ? 30
                : timeframe.value === Timeframe.Yearly
                ? 30 * 3
                : 365 * 20;

        const since = (
            now.setDate(now.getDate() - days).valueOf() / 1000
        ).toFixed();

        return {
            since,
            first: 500,
            trader: address,
            operation: operation.value,
        };
    }, [timeframe.value, operation.value, address]);

    const {
        data: historyData,
        loading: isLoadingHistory,
        refetch: refetchHistory,
    } = useQuery(
        operation.value === Operation.All
            ? GET_HISTORY
            : operation.value === Operation.Cancelled
            ? GET_HISTORY_CANCELLED
            : GET_HISTORY_WITH_OPERATION,
        {
            skip: !address,
            pollInterval: 5000,
            variables: historyVariables,
            fetchPolicy: "cache-and-network",
        }
    );

    const onChangeTimeframe = async (option: SelectOptionType) => {
        setTimeframe(option);
        refetchHistory();
    };

    const onChangeOperation = async (option: SelectOptionType) => {
        setOperation(option);
        refetchHistory();
    };

    const toggleShowAllPairs = useCallback(
        () => setShowAllPairs(!showAllPairs),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showAllPairs]
    );

    const tabs: Partial<IListTabOption>[] = useMemo(
        () => [
            {
                id: Tab.TRADES,
                text: `Trades (${
                    tradesData?.trades?.length
                        ? `${tradesData?.trades?.length}`
                        : "0"
                })`,
            },
            {
                id: Tab.ORDERS,
                text: `Orders ${
                    ordersData?.length ? `(${ordersData?.length})` : ""
                }`,
            },
            {
                id: Tab.HISTORY,
                text: `History`,
            },
        ],
        [tradesData?.trades?.length, ordersData?.length]
    );

    return {
        address,
        userData: userData?.user,
        historyData: historyData?.orders || [],
        tradesData: tradesData?.trades || [],
        ordersData,
        isLoadingTrades,
        isLoadingOrders,
        isLoadingHistory,
        pair,
        pairs,
        showAllPairs,
        timeframe,
        tabs,
        isCollapsed,
        toggleCollapsed,
        operation,
        onChangeOperation,

        // Columns
        initialColumns,
        onChangeTimeframe,
        toggleShowAllPairs,
        onChangeTab,
        currentTab,
    };
}

const TradeListProvider = ({
    children,
    pair,
    pairs,
    address,
    isCollapsed,
    toggleCollapsed,
}: {
    children: JSX.Element;
    pair: Pair | null;
    pairs: Map<string, Pair>;
    address?: `0x${string}`;
    isCollapsed?: boolean;
    toggleCollapsed?: () => void;
}) => {
    const value = useTradeList({
        address,
        pairs,
        pair,
        isCollapsed,
        toggleCollapsed,
    });

    return (
        // @ts-ignore
        <TradeListContext.Provider value={value}>
            {children}
        </TradeListContext.Provider>
    );
};

const useTradeListContext = () => useContext(TradeListContext);

export { TradeListProvider, useTradeListContext };

export const GET_TRADES = gql`
    query ListTrades($trader: Bytes, $orderDirection: String)
    @api(name: subgraph) {
        trades(where: { trader: $trader, isOpen: true }) {
            id
            index
            trader
            tradeNotional
            tradeType
            openPrice
            isBuy
            notional
            collateral
            leverage
            highestLeverage
            stopLossPrice
            takeProfitPrice
            timestamp
            funding
            rollover
            pair {
                id
                from
                to
                feed
                accRollover
                accFundingLong
                accFundingShort
                curFundingLong
                curFundingShort
                lastFundingRate
                maxFundingFeePerBlock
                lastFundingBlock
                lastFundingTime
                rolloverFeePerBlock
                lastRolloverBlock
                maxOI
                longOI
                shortOI
                maxLeverage
                fee {
                    minLevPos
                }
                group {
                    name
                    maxLeverage
                    minLeverage
                }
                hillInflectionPoint
                hillPosScale
                hillNegScale
                lastOiDelta
                springFactor
                sFactorUpScaleP
                sFactorDownScaleP
                lastTradePrice
            }
        }
    }
`;

export const GET_ORDERS = gql`
    query ListOrders($trader: Bytes) @api(name: subgraph) {
        limits(first: 1000, where: { trader: $trader, isActive: true }) {
            id
            trader
            initiatedAt
            isBuy
            notional
            tradeNotional
            collateral
            leverage
            limitType
            openPrice
            stopLossPrice
            takeProfitPrice
            initiatedAt
            updatedAt
            pair {
                id
                from
                to
                feed
                longOI
                shortOI
                maxOI
                makerFeeP
                takerFeeP
                makerMaxLeverage
                fee {
                    liqFeeP
                    oracleFee
                }
                group {
                    name
                }
                # hillInflectionPoint
                # hillPosScale
                # hillNegScale
                # lastOiDelta
                # springFactor
                # sFactorUpScaleP
                # sFactorDownScaleP
                # lastTradePrice
            }
        }
        orders(
            first: 1000
            where: {
                trader: $trader
                isPending: true
                isCancelled: false
                orderType: "Market"
            }
        ) {
            id
            isBuy
            tradeNotional
            notional
            trader
            collateral
            leverage
            orderType
            orderAction
            price
            initiatedAt
            totalProfitPercent
            profitPercent
            isPending
            amountSentToTrader
            initiatedTx
            isCancelled
            cancelReason
            rolloverFee
            fundingFee
            tradeID
            pair {
                id
                from
                to
                feed
                longOI
                shortOI
                maxOI
                makerFeeP
                takerFeeP
                makerMaxLeverage
                fee {
                    liqFeeP
                    oracleFee
                }
                group {
                    name
                }
                # hillInflectionPoint
                # hillPosScale
                # hillNegScale
                # lastOiDelta
                # springFactor
                # sFactorUpScaleP
                # sFactorDownScaleP
                # lastTradePrice
            }
        }
    }
`;

const GET_HISTORY_CANCELLED = gql`
    query ListCancelledHistory($trader: Bytes, $since: BigInt)
    @api(name: subgraph) {
        orders(
            where: {
                trader: $trader
                isPending: false
                executedAt_gte: $since
                isCancelled: true
            }
            first: 1000
            orderBy: executedAt
            orderDirection: desc
        ) {
            id
            isBuy
            trader
            tradeNotional
            notional
            collateral
            leverage
            orderType
            orderAction
            price
            initiatedAt
            executedAt
            executedTx
            isCancelled
            cancelReason
            profitPercent
            totalProfitPercent
            isPending
            amountSentToTrader
            rolloverFee
            fundingFee
            pair {
                id
                from
                to
                feed
                longOI
                shortOI
                makerFeeP
                takerFeeP
                makerMaxLeverage
                fee {
                    liqFeeP
                    oracleFee
                }
                group {
                    name
                }
                # hillInflectionPoint
                # hillPosScale
                # hillNegScale
                # lastOiDelta
                # springFactor
                # sFactorUpScaleP
                # sFactorDownScaleP
                # lastTradePrice
            }
        }
    }
`;

const GET_HISTORY_WITH_OPERATION = gql`
    query ListOperationsHistory(
        $trader: Bytes
        $since: BigInt
        $operation: String
    ) @api(name: subgraph) {
        orders(
            where: {
                trader: $trader
                isPending: false
                executedAt_gte: $since
                orderAction: $operation
            }
            first: 1000
            orderBy: executedAt
            orderDirection: desc
        ) {
            id
            isBuy
            trader
            tradeNotional
            notional
            collateral
            leverage
            orderType
            orderAction
            price
            initiatedAt
            executedAt
            executedTx
            isCancelled
            cancelReason
            profitPercent
            totalProfitPercent
            isPending
            amountSentToTrader
            rolloverFee
            fundingFee
            pair {
                id
                from
                to
                feed
                longOI
                shortOI
                makerFeeP
                takerFeeP
                makerMaxLeverage
                fee {
                    liqFeeP
                    oracleFee
                }
                group {
                    name
                }
                # hillInflectionPoint
                # hillPosScale
                # hillNegScale
                # lastOiDelta
                # springFactor
                # sFactorUpScaleP
                # sFactorDownScaleP
                # lastTradePrice
            }
        }
    }
`;

const GET_HISTORY = gql`
    query ListOrdersHistory($trader: Bytes, $since: BigInt)
    @api(name: subgraph) {
        orders(
            where: { trader: $trader, isPending: false, executedAt_gte: $since }
            first: 1000
            orderBy: executedAt
            orderDirection: desc
        ) {
            id
            isBuy
            trader
            notional
            tradeNotional
            collateral
            leverage
            orderType
            orderAction
            price
            initiatedAt
            executedAt
            executedTx
            isCancelled
            cancelReason
            profitPercent
            totalProfitPercent
            isPending
            amountSentToTrader
            rolloverFee
            fundingFee
            pair {
                id
                from
                to
                feed
                longOI
                shortOI
                group {
                    name
                }
                # hillInflectionPoint
                # hillPosScale
                # hillNegScale
                # lastOiDelta
                # springFactor
                # sFactorUpScaleP
                # sFactorDownScaleP
                # lastTradePrice
            }
        }
    }
`;
