import { Flex } from "@atoms/Flex";
import { Button } from "@molecules/Button";
import { useTheme } from "styled-components/native";
import { useRef, useState, useCallback, useEffect } from "react";
import { TextInput } from "react-native";
import { useAppContext } from "@contexts/app";
import { useNotificationContext } from "@contexts/notification";
import { useUser } from "@contexts/hooks/useUser";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { useRouter } from "next/router";
import { UserFe } from "gql/graphql";

export const AccessCodeInput = () => {
    const theme = useTheme();
    const { userFE, refetchUser } = useAppContext();
    const { address } = useOstiumAccount();
    const { notify } = useNotificationContext();
    const { validateAccessCode } = useUser({ address });
    const { query } = useRouter();
    const { ref } = query;

    const [loading, setLoading] = useState(false);
    const [buttonText, setButtonText] = useState("Submit Code");
    const [error, setError] = useState(false);
    const [code, setCode] = useState(
        userFE?.accessCode?.split("") || ["", "", "", "", ""]
    );

    const inputRefs = useRef<(TextInput | null)[]>([]);
    const fullCode = code.join("");
    const isValidFiveDigitCode = fullCode.length === 5 || fullCode === "";

    const accessCode = userFE?.accessCode || "";

    useEffect(() => {
        if (ref && ref.length) {
            //@ts-ignore
            const firstFourChars = ref.slice(0, 5).toUpperCase().split("");

            // Set the first 4 characters in the state, and keep the rest as empty strings
            setCode((prevCode) => [
                ...firstFourChars,
                ...prevCode.slice(firstFourChars.length),
            ]);
        }
    }, [ref]);

    const handleChangeText = (text: string, index: number) => {
        // Detect if the entire code is being pasted
        if (text.length > 1) {
            const newCode = text.slice(0, 5).toUpperCase().split("");
            setCode(newCode);
            // Move focus to the next input or submit the form
            (inputRefs.current[4] as TextInput | null)?.focus?.();
            return;
        }

        // Handle regular character input
        const newCode = [...code];
        newCode[index] = text.toUpperCase();
        setCode(newCode);

        if (text.length === 1 && index < inputRefs.current.length - 1) {
            (inputRefs.current[index + 1] as TextInput | null)?.focus?.();
        }
    };

    const handleKeyPress = ({ nativeEvent }: any, index: number) => {
        if (nativeEvent.key === "Backspace" && index > 0 && !code[index]) {
            (inputRefs.current[index - 1] as TextInput | null)?.focus?.();
        }
    };

    const onValidateAccessCode = useCallback(async () => {
        if (!isValidFiveDigitCode) return;
        setLoading(true);
        if (fullCode.toLowerCase() === userFE?.referralCode?.toLowerCase()) {
            setError(true);
            notify({
                title: "Can't use your own referral code",
                type: "Error",
            });

            return;
        }
        const data = await validateAccessCode(
            address,
            fullCode.toUpperCase(),
            userFE as UserFe
        );
        if (data) {
            if (data.isAccessCodeValid) {
                notify({
                    title: "Access code validated",
                    type: "Success",
                });
                setError(false);
                await refetchUser();
                setButtonText("✓ Code Used");
            } else {
                setError(true);
                notify({
                    title: "Access code invalid",
                    type: "Error",
                });
            }
        }
        setLoading(false);
    }, [
        isValidFiveDigitCode,
        validateAccessCode,
        fullCode,
        notify,
        refetchUser,
        address,
        userFE,
    ]);

    return (
        <Flex
            justify="space-between"
            style={{
                borderRadius: theme.spacing.tiny,
                backgroundColor: theme.color.rgba(theme.color.white, 0.1),
                padding: theme.spacing.medium,
            }}
        >
            <Flex gap={theme.spacing.smaller}>
                {[0, 1, 2, 3, 4].map((index) => (
                    <TextInput
                        key={index}
                        //@ts-ignore
                        ref={(ref) => (inputRefs.current[index] = ref)}
                        value={code[index]}
                        onChangeText={(text) => {
                            if (error) setError(false);
                            handleChangeText(text.toUpperCase(), index);
                        }}
                        editable={
                            accessCode.length === 0 || fullCode !== accessCode
                        }
                        onKeyPress={(e) => handleKeyPress(e, index)}
                        style={{
                            borderWidth: 1,
                            borderColor: !isValidFiveDigitCode
                                ? theme.color.red
                                : "",
                            backgroundColor: theme.color.rgba(
                                theme.color.white,
                                0.1
                            ),
                            color: theme.color.white,
                            textAlign: "center",
                            fontSize: 16,
                            width: 30,
                            borderRadius: 5,
                        }}
                    />
                ))}
            </Flex>
            <Button
                text={
                    loading
                        ? "InProgress.."
                        : fullCode === accessCode && accessCode.length > 0
                        ? "✓ Code Used"
                        : buttonText
                }
                primary
                stroke
                small
                disabled={
                    loading ||
                    fullCode === accessCode ||
                    buttonText === "✓ Code Used"
                }
                onPress={onValidateAccessCode}
            />
        </Flex>
    );
};
