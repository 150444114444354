import { Avatar } from "@atoms/Avatar";
import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Portal } from "@atoms/Portal";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { PriceProvider } from "@contexts/price";
import { useSmartWalletContext } from "@contexts/smartWallet";
import { useWeb3Context } from "@contexts/web3";
import { PortalProvider } from "@gorhom/portal";
import { useBalance } from "@hooks/useBalance";
import { Image } from "@atoms/Image";
import { useMedia } from "@hooks/useMedia";
import { useOutsideAlerter } from "@hooks/useOutsidePress";
import { Collapsible } from "@molecules/Animation";
import { Button } from "@molecules/Button";
import { Statistic } from "@molecules/Statistic";
import { Toggle } from "@molecules/Toggle";
import { Sidebar } from "@organisms/Header/Sidebar";
import { Line } from "@screens/Trade/components/Header/PairStatistics";
import {
    AppSettings,
    PageSettings,
    SettingsButton,
} from "@screens/components/Header/User/Sidebar/Settings";
import { formatAddress, formatPrice, getBaseS3URL } from "@utils";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { Linking, Pressable, ScrollView } from "react-native";
import { useTheme } from "styled-components/native";
import { useDisconnect } from "wagmi";
import { UserGraph } from "./Sidebar/UserGraph";
import { NetworkSwitcher } from "@organisms/Header/NetworkSwitcher";
import { useChainalysis } from "@hooks/useChainalysis";
import { useRouting } from "expo-next-react-navigation";
import { useSearchParams } from "next/navigation";
import { useLogin, usePrivy } from "@privy-io/react-auth";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";
import { useUser } from "@contexts/hooks/useUser";
import { useNotificationContext } from "@contexts/notification";
import Modal from "@organisms/Modal";
import OnboardingModal from "@screens/components/onboarding";
import { EditUsername } from "./EditUsername";

export const UserSettings = () => {
    const theme = useTheme();
    const media = useMedia();
    const { navigate } = useRouting();
    const {
        settings,
        resetAppSettings,
        resetApp,
        toggleSmartAccount,
        setIsOneClickTradingEnabled,
        isOneClickTradingEnabled,
        setIsWithdrawModalVisible,
        setShowOnboardingModal,
        setShowEnableCTModal,
        showEnableCTModal,
        userFE,
        showUsernameModal,
        setShowUsernameModal,
    } = useAppContext();
    const { address, wagmiAddress } = useOstiumAccount();

    const { logoutSmartAccount } = usePrivySmartWalletContext();

    const { notify } = useNotificationContext();
    const { authenticated, logout } = usePrivy();

    const { login } = useLogin({
        onComplete({ user }) {
            if (user && (user.google?.email || user?.email?.address)) {
                window?.localStorage?.setItem(
                    "isOneClickTradingEnabled",
                    "true"
                );
                setIsOneClickTradingEnabled(true);
            }
        },
    });
    const [assesDone, setAssesDone] = useState(false);
    const searchParams = useSearchParams();
    //const wallet = searchParams.get("wallet");

    const { disconnect } = useDisconnect();
    // const checkRiskForWallet = useChainalysis({
    //     assesDone,
    //     //@ts-ignore
    //     setAssesDone,
    //     address: address as `0x${string}`,
    //     redirect: () =>
    //         navigate({
    //             routeName: "/restricted",
    //             params: {
    //                 type: "wallet",
    //             },
    //         }),
    // });

    const { currentChain } = useWeb3Context();

    const [isHovering, setIsHovering] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenSettings, setIsOpenSettings] = useState(false);

    const formattedAddress = useMemo(() => {
        return `${address?.substring(0, 4)}...${address?.substring(
            address?.length - 4,
            address?.length
        )}`;
    }, [address]);

    const toggleOpen = () => {
        if (isOpenSettings) {
            setIsOpenSettings(false);
        }
        setIsOpen((prev) => !prev);
    };

    const toggleOpenSettings = () => {
        setIsOpenSettings(!isOpenSettings);
    };

    const onDisconnect = () => {
        disconnect();
        logoutSmartAccount();
        toggleOpen();
    };

    const onExplorer = () => {
        Linking.openURL(`${currentChain?.explorer}address/${address}`);
    };

    const onCopy = () => {
        navigator.clipboard.writeText(address || "");
    };

    const onCopyAddress = useCallback(() => {
        if (address) {
            notify({
                title: "Address copied to clipboard",
            });
            navigator.clipboard.writeText(address);
        }
    }, [notify, address]);

    const settingsSidebarRef = useRef(null);
    const userSidebarRef = useRef(null);

    useOutsideAlerter(userSidebarRef, () => {
        setTimeout(() => {
            if (isOpen && !isOpenSettings) {
                setIsOpen(false);
            }
        }, 0);
    });

    useOutsideAlerter(settingsSidebarRef, () => {
        setTimeout(() => {
            if (isOpenSettings) {
                setIsOpenSettings(false);
                setIsOpen(false);
            }
        }, 0);
    });

    if (address) {
        return (
            <PortalProvider>
                <Pressable disabled={isOpen} onPress={toggleOpen}>
                    <Box
                        align="center"
                        justify="center"
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                        style={{
                            height: 32,
                            borderRadius: theme.radius.small,
                            paddingHorizontal: theme.spacing.small,
                            backgroundColor: theme.color.rgba(
                                theme.color.white,
                                isOpen || isHovering ? 0.2 : 0.1
                            ),
                        }}
                    >
                        <Flex
                            align="center"
                            style={{ width: "100%" }}
                            gap={theme.spacing.small}
                        >
                            <Avatar size={18} string={address} />
                            {settings.hideWallet ? null : (
                                <Text smaller bold primary selectable={false}>
                                    {userFE &&
                                    userFE.username &&
                                    userFE.username.length
                                        ? userFE.username
                                        : formattedAddress}
                                </Text>
                            )}

                            {(settings.hideWallet && settings.hideBalance) ||
                            (settings.hideBalance && !settings.hideWallet) ||
                            (!settings.hideBalance && settings.hideWallet) ||
                            media.isMobile ? null : (
                                <Line height={18} />
                            )}

                            {settings.hideBalance || media.isMobile ? null : (
                                <Balance />
                            )}
                        </Flex>
                    </Box>
                </Pressable>
                <OnboardingModal />
                {!media.isMobile && (
                    <SettingsButton
                        onPress={toggleOpenSettings}
                        isOpen={isOpenSettings}
                    />
                )}

                <Portal>
                    <Sidebar
                        ref={userSidebarRef}
                        isActive={isOpen}
                        renderFooter={
                            <Box>
                                <Flex
                                    gap={theme.spacing.smallest}
                                    style={{
                                        padding: theme.spacing.bigger,
                                    }}
                                >
                                    {/* <Button
                                smallest
                                stroke
                                iconProps={{
                                    name: "copy",
                                }}
                                text="Copy"
                                onPress={onCopy}
                            /> */}
                                    <Button
                                        style={{
                                            flex: 1,
                                        }}
                                        stroke
                                        noBorder
                                        text="Explorer"
                                        iconProps={{
                                            name: "external",
                                        }}
                                        onPress={onExplorer}
                                    />
                                    <Button
                                        style={{
                                            flex: 1,
                                        }}
                                        noBorder
                                        stroke
                                        text="Disconnect"
                                        iconProps={{
                                            name: "disconnect",
                                        }}
                                        onPress={onDisconnect}
                                    />
                                </Flex>
                            </Box>
                        }
                    >
                        <Box flex={1}>
                            <Box
                                style={{
                                    padding: theme.spacing.big,
                                }}
                            >
                                <Box gap={theme.spacing.medium}>
                                    <Flex justify="space-between">
                                        <Flex
                                            gap={theme.spacing.smallest}
                                            flex={1}
                                            direction={"column"}
                                        >
                                            {userFE && userFE?.username ? (
                                                <Pressable
                                                    onPress={() => {
                                                        setShowUsernameModal(
                                                            true
                                                        );
                                                        toggleOpen();
                                                    }}
                                                >
                                                    <Flex
                                                        style={{ gap: 8 }}
                                                        align="center"
                                                    >
                                                        <Text
                                                            sml
                                                            style={{
                                                                color: theme.color.rgba(
                                                                    theme.color
                                                                        .greyNew,
                                                                    0.4
                                                                ),
                                                            }}
                                                        >
                                                            {userFE?.username}
                                                        </Text>
                                                        <Image
                                                            source={getBaseS3URL(
                                                                "edit_btn_2.png"
                                                            )}
                                                            width={14}
                                                            height={14}
                                                            alt={`edit_btn"`}
                                                        />
                                                    </Flex>
                                                </Pressable>
                                            ) : (
                                                <Pressable
                                                    onPress={() => {
                                                        setShowUsernameModal(
                                                            true
                                                        );
                                                        toggleOpen();
                                                    }}
                                                >
                                                    <Flex
                                                        style={{ gap: 8 }}
                                                        align="center"
                                                    >
                                                        <Text
                                                            sml
                                                            style={{
                                                                color: theme.color.rgba(
                                                                    theme.color
                                                                        .greyNew,
                                                                    0.4
                                                                ),
                                                            }}
                                                        >
                                                            Create Username
                                                        </Text>
                                                        <Image
                                                            source={getBaseS3URL(
                                                                "create_btn.png"
                                                            )}
                                                            width={14}
                                                            height={14}
                                                            alt={`edit_btn"`}
                                                        />
                                                    </Flex>
                                                </Pressable>
                                            )}

                                            <Pressable onPress={onCopyAddress}>
                                                <Flex
                                                    style={{ gap: 8 }}
                                                    align="center"
                                                >
                                                    <Text
                                                        smaller
                                                        style={{
                                                            color: theme.color
                                                                .greyNew,
                                                        }}
                                                    >
                                                        {formattedAddress}
                                                    </Text>
                                                    <Image
                                                        lazy
                                                        source={`${getBaseS3URL(
                                                            "copy_btn.png"
                                                        )}`}
                                                        height={16}
                                                        width={16}
                                                        fill={true}
                                                        alt={`/copy`}
                                                    />
                                                </Flex>
                                            </Pressable>

                                            {/* <Statistic
                                                small
                                                style={{
                                                    gap: theme.spacing.smallest,
                                                }}
                                                label={formattedAddress}
                                            >
                                                <Balance />
                                            </Statistic>
                                            <Pressable onPress={onCopyAddress}>
                                                <Image
                                                    lazy
                                                    source={`${getBaseS3URL(
                                                        "copy.png"
                                                    )}`}
                                                    height={16}
                                                    width={16}
                                                    fill={true}
                                                    alt={`/copy`}
                                                />
                                            </Pressable> */}
                                        </Flex>
                                        <NetworkSwitcher />
                                    </Flex>
                                </Box>
                            </Box>

                            <Box
                                style={{
                                    borderTopWidth: 1,
                                    // borderBottomWidth: 1,
                                    borderColor: theme.color.rgba(
                                        theme.color.white,
                                        0.1
                                    ),
                                    padding: theme.spacing.bigger,
                                }}
                            >
                                <PriceProvider>
                                    <UserGraph
                                        onPress={toggleOpen}
                                        wagmiAddress={wagmiAddress}
                                        address={address}
                                        isOpen={isOpen}
                                        toggleSmartAccount={toggleSmartAccount}
                                        isOneClickTradingEnabled={
                                            isOneClickTradingEnabled
                                        }
                                        setIsWithdrawModalVisible={
                                            setIsWithdrawModalVisible
                                        }
                                        setShowEnableCTModal={
                                            setShowEnableCTModal
                                        }
                                        showEnableCTModal={showEnableCTModal}
                                    />
                                </PriceProvider>
                            </Box>
                        </Box>
                    </Sidebar>
                    {/* <Box
                            style={{
                                padding: theme.spacing.bigger,
                            }}
                        >
                            <Box
                                style={{
                                    borderRadius: theme.radius.big,
                                    backgroundColor: theme.color.rgba(
                                        theme.color.white,
                                        0.1
                                    ),
                                }}
                            >
                                <Box
                                    style={{
                                        padding: theme.spacing.bigger,
                                        borderBottomWidth: 1,
                                        borderColor: theme.color.rgba(
                                            theme.color.white,
                                            0.1
                                        ),
                                    }}
                                >
                                    <Text small bold>
                                        Operations
                                    </Text>
                                </Box>
                                <Operations address={address} />
                            </Box>
                        </Box> */}

                    <Sidebar ref={settingsSidebarRef} isActive={isOpenSettings}>
                        <Pressable onPress={toggleOpenSettings}>
                            <Flex
                                align="center"
                                gap={theme.spacing.smaller}
                                style={{
                                    padding: theme.spacing.bigger,
                                    borderBottomWidth: 1,
                                    borderColor: theme.color.rgba(
                                        theme.color.white,
                                        0.1
                                    ),
                                }}
                            >
                                <Icon name="arrow" size={14} rotate={-90} />
                                <Box>
                                    <Text bold small selectable={false}>
                                        Settings
                                    </Text>
                                </Box>
                            </Flex>
                        </Pressable>

                        <ScrollView style={{ flex: 1 }}>
                            <AppSettings />
                            <PageSettings />
                        </ScrollView>
                        <Flex
                            style={{
                                paddingHorizontal: theme.spacing.big,
                            }}
                            gap={theme.spacing.small}
                            direction="column"
                        >
                            <Button
                                stroke
                                primary
                                big
                                text="Reset layout"
                                onPress={resetAppSettings}
                            />
                            <Button
                                stroke
                                primary
                                big
                                text="Reset app"
                                onPress={resetApp}
                            />
                        </Flex>
                    </Sidebar>

                    {/* <Box
                            style={{
                                paddingHorizontal: theme.spacing.big,
                                paddingBottom: theme.spacing.big,
                            }}
                        >
                            <SmartWalletDropdown />
                        </Box> */}
                    <Modal
                        title={`${
                            userFE?.username ? "Edit" : "Create"
                        } Your Username`}
                        isVisible={showUsernameModal}
                        onClose={() => setShowUsernameModal(false)}
                    >
                        <EditUsername />
                    </Modal>
                </Portal>
            </PortalProvider>
        );
    }

    const handleLogin = async () => {
        if (authenticated) {
            await logoutSmartAccount();
            // Add a slight delay to ensure state updates are processed
            setTimeout(() => {
                login();
            }, 100);
        } else {
            login();
        }
    };

    return (
        <Box>
            <Button
                style={{ height: 32 }}
                primary
                text={"Connect"}
                onPress={handleLogin}
            />
        </Box>
    );
};

export const Balance = memo(() => {
    const { currentChain } = useWeb3Context();
    const { address } = useOstiumAccount();

    const { balance } = useBalance({
        address: address as `0x${string}`,
        watch: true,
    });

    return (
        <>
            <Text smallest bold selectable={false}>
                {`${formatPrice(balance?.formatted, {
                    decimals: 2,
                })} ${currentChain?.symbol}`}
            </Text>
        </>
    );
});

const SmartWalletDropdown = () => {
    const theme = useTheme();
    const { currentChain } = useWeb3Context();

    const { address, connect, disconnect } = useSmartWalletContext();

    const onToggleSmartAccount = () => {
        if (address) {
            disconnect();
        } else {
            connect();
        }
    };

    const formattedAddress = useMemo(() => {
        return formatAddress(address);
    }, [address]);

    const onCopy = () => {
        navigator.clipboard.writeText(address || "");
    };

    const onExplorer = () => {
        Linking.openURL(`${currentChain?.explorer}address/${address}`);
    };

    return (
        <Box
            style={{
                backgroundColor: theme.color.rgba(theme.color.white, 0.1),
                borderRadius: theme.radius.small,
            }}
        >
            <Collapsible
                headerStyle={{
                    padding: theme.spacing.big,
                }}
                noIcon
                renderHeader={
                    <Flex justify="space-between">
                        <Flex align="center" gap={theme.spacing.small}>
                            <Icon name="lightning" size={20} primary />

                            <Text bold small>
                                Smart wallet
                            </Text>
                        </Flex>

                        <Toggle
                            isActive={!!address}
                            text="Enable Smart Wallet"
                            onPress={onToggleSmartAccount}
                        />
                    </Flex>
                }
            >
                <Box
                    style={{
                        padding: theme.spacing.big,
                        paddingTop: 0,
                    }}
                >
                    <Flex
                        justify="space-between"
                        align="center"
                        style={{
                            borderRadius: theme.radius.small,
                            padding: theme.spacing.big,
                            backgroundColor: theme.color.black,
                            borderWidth: 2,
                            borderColor: theme.color.rgba(
                                theme.color.white,
                                0.2
                            ),
                        }}
                    >
                        <Flex align="center" gap={theme.spacing.small}>
                            <Avatar string={address} />
                            <Statistic
                                small
                                label="Wallet Address"
                                value={formattedAddress}
                                style={{
                                    gap: theme.spacing.smallest,
                                }}
                            />
                        </Flex>

                        <Flex gap={theme.spacing.smallest}>
                            <Button
                                small
                                stroke
                                iconProps={{
                                    name: "copy",
                                }}
                                onPress={onCopy}
                            />
                            <Button
                                small
                                stroke
                                iconProps={{
                                    name: "external",
                                }}
                                onPress={onExplorer}
                            />
                        </Flex>
                    </Flex>
                </Box>
            </Collapsible>
        </Box>
    );
};
