import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { wagmiConfig } from "../wagmiConfig";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { watchAccount } from "@wagmi/core";
import { UserFe } from "gql/graphql";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIdentityToken, usePrivy } from "@privy-io/react-auth";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";

export const REFERRAL_CODE = "REFERRAL_CODE_V2";

export function useUser({ address }: { address?: `0x${string}` }) {
    const { getKey, saveKey, deleteKey } = useLocalStorage();
    const [userFE, setUserFE] = useState<UserFe | null>(null);
    const [userFELoading, setUserFELoading] = useState<boolean>(true);
    const [showGetStarted, setShowGetStarted] = useState(false);
    const { query } = useRouter();
    const { ref } = query;
    const { logoutSmartAccount } = usePrivySmartWalletContext();
    const { identityToken } = useIdentityToken();
    const { user } = usePrivy();

    const refetch = useCallback(async ({ address }: { address: string }) => {
        try {
            const response = await fetch(
                `/api/user/get-userFE?address=${address?.toLowerCase()}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json", // Ensure the request is sent with the correct content type
                    },
                }
            ).then((res) => res.json());

            return response;
        } catch (e) {
            console.log("refetch error", e);
        }
    }, []);

    const addReferral = useCallback(
        async (
            address: any,
            accessCode: string | undefined,
            userFe: UserFe | undefined
        ) => {
            try {
                const storedReferralCode = accessCode
                    ? accessCode
                    : await getKey(REFERRAL_CODE);
                if (storedReferralCode) {
                    const params = JSON.stringify({
                        address: address.toLowerCase(),
                        userWalletAddress: address.toLowerCase(),
                        referralCode: storedReferralCode.toUpperCase(),
                    });

                    const result = await fetch(`/api/user/add-referral`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json", // Ensure the request is sent with the correct content type
                            ...(identityToken
                                ? { "privy-id-token": identityToken }
                                : {}),
                        },
                        body: params,
                    }).then((res) => res.json());

                    if (result.error) {
                        console.warn("Referral Error", result);
                        return;
                    }
                }
            } catch (err) {
                console.warn(err);
            }
        },
        [getKey, identityToken]
    );

    const validateAccessCode = useCallback(
        async (address: any, accessCode: string, userFe: UserFe) => {
            try {
                const params = JSON.stringify({
                    accessCode,
                });
                const result = await fetch(`/api/user/validate-access-code`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json", // Ensure the request is sent with the correct content type
                    },
                    body: params,
                }).then((res) => res.json());

                if (result.error) {
                    console.warn("Validate Acccess Code Error", result.error);
                    return result;
                }
                if (result?.isAccessCodeValid === true) {
                    await addReferral(address, accessCode, userFe);
                    saveKey(REFERRAL_CODE, accessCode.toUpperCase());
                    refetch({ address: address?.toLowerCase() as string });
                }
                return result;
            } catch (err) {
                console.warn(err);
            }
        },
        [saveKey, addReferral, refetch]
    );

    useEffect(() => {
        if (ref && address && userFE) {
            console.warn("Setting referral code locally", ref);
            validateAccessCode(address, ref as string, userFE);
        }
    }, [ref, address, validateAccessCode, userFE]);

    const refetchUser = useCallback(async () => {
        try {
            const data = await refetch({
                address: address?.toLowerCase() as string,
            });

            if (data) {
                setUserFE(data);
                if (!data?.ftueDone) setShowGetStarted(true);
            }

            setUserFELoading(false);
        } catch (err) {
            console.warn(err);
        }
    }, [address, refetch]);

    const toggleGetStarted = useCallback(() => {
        setShowGetStarted((prev) => !prev);
    }, []);

    const register = useCallback(
        async (address: `0x${string}`, accessCode: string | undefined) => {
            try {
                const params = JSON.stringify({
                    address: address.toLowerCase(),
                    userWalletAddress: address.toLowerCase(),
                });
                const response = await fetch(`/api/user/register-user`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json", // Ensure the request is sent with the correct content type
                        ...(identityToken
                            ? { "privy-id-token": identityToken }
                            : {}),
                    },
                    body: params,
                }).then((res) => res.json());

                if (response.error) {
                    setUserFELoading(false);
                    return;
                }

                setUserFE(response);
                setUserFELoading(false);
                if (!response?.ftueDone) setShowGetStarted(true);

                //console.warn(userFE);
                // const result = await addReferral(address, accessCode);
                // console.warn(result);
                // console.warn("Referral code added");
                // await deleteKey(REFERRAL_CODE);
                // console.warn("Referral code deleted");
                return;
            } catch (err) {
                console.warn(err);
            }
        },
        [identityToken]
    );

    const previousAddress = useRef(address);

    useEffect(() => {
        const unwatch = watchAccount(wagmiConfig, {
            async onChange(account) {
                console.log("Account changed!", account);
                try {
                    if (account?.isConnected) {
                        if (account?.address && user) {
                            if (
                                user &&
                                (user.google?.email || user?.email?.address)
                            ) {
                                await register(
                                    user?.smartWallet?.address as `0x${string}`,
                                    undefined
                                );
                                return;
                            }

                            if (
                                previousAddress.current &&
                                previousAddress.current !== account?.address &&
                                userFE?.id
                            )
                                setUserFE(null);

                            previousAddress.current = account?.address;

                            await register(account?.address, undefined);
                        }
                    }

                    if (
                        account?.isDisconnected &&
                        account?.status === "disconnected"
                    ) {
                        if (
                            user &&
                            (user.google?.email || user?.email?.address)
                        ) {
                            return;
                        }

                        window?.localStorage?.setItem(
                            "isOneClickTradingEnabled",
                            "false"
                        );
                        setUserFE(null);
                        if (user) {
                            logoutSmartAccount();
                        }
                    }
                } catch (err) {
                    console.warn(err);
                }
            },
        });

        return () => {
            unwatch();
        };
    }, [logoutSmartAccount, register, user, userFE?.id]);

    return {
        showGetStarted,
        refetchUser,
        toggleGetStarted,
        setUserFE,
        validateAccessCode,
        userFE,
        userFELoading,
        addReferral,
        register,
    };
}

const GET_USER_FE = gql`
    query getUserFE($address: String!) {
        userFE(where: { address: $address }) {
            id
            address
            username
            ftueDone
            referralCode
            signedMessageTimestamp
            accessCode
        }
    }
`;
