import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { gql, useQuery } from "@hooks/useApollo";
import { useMedia } from "@hooks/useMedia";
import { Select } from "@molecules/Select";
import { createDataset } from "@organisms/Chart";
import { Doughnut } from "@organisms/Chart/Doughnut";
import { AssetClass, getUserCollateralMetadata } from "@screens/Trade/utils";
import { formatLargeNumber } from "@utils";
import { memo, useMemo, useState, useRef, useEffect } from "react";
import { useTheme } from "styled-components/native";
import { GET_USER_TRADES } from "../Overview";

export const UserGroupStatistics = memo(
    ({ address }: { address?: `0x${string}` }) => {
        const theme = useTheme();
        const media = useMedia();

        // Track render count and updates
        const renderCount = useRef(0);
        renderCount.current += 1;

        const options = [
            {
                text: "All",
                value: "all",
            },
            {
                text: "Open",
                value: "open",
            },
            {
                text: "Closed",
                value: "closed",
            },
        ];

        const [selected, setSelected] = useState(options[0]);

        const { data } = useQuery(GET_USER_GROUP, {
            variables: {
                user: address?.toLowerCase(),
            },
            fetchPolicy: "cache-and-network",
            skip: !address,
        });

        const { data: tradesData } = useQuery(GET_USER_TRADES, {
            variables: {
                trader: address?.toLowerCase(),
            },
            skip: !address,
            pollInterval: 10000,
        });

        const metadata = useMemo(() => {
            const result = getUserCollateralMetadata(
                tradesData?.trades,
                data?.userGroupStats,
                selected.value
            );

            console.log("UserGroupStatistics metadata updated:", {
                metadataItemCount: result.length,
                renderCount: renderCount.current,
            });

            return result;
        }, [data?.userGroupStats, selected.value, tradesData?.trades]);

        const datasets = useMemo(() => {
            return [
                createDataset(
                    "Collateral",
                    metadata?.map((i) => i.total),
                    theme.color.background,
                    {
                        // @ts-ignore
                        type: "doughnut",
                        // @ts-ignore
                        backgroundColor: metadata?.map((i) => i.color),
                        hoverBackgroundColor: metadata?.map((i) => i.color),
                        borderWidth: 0,
                        borderRadius: theme.radius.big,
                    }
                ),
            ];
        }, [metadata, theme.color.background, theme.radius.big]);

        return (
            <Box flex={media.isMobile ? undefined : 1}>
                <Flex
                    justify="space-between"
                    align="center"
                    style={{
                        borderBottomWidth: 1,
                        borderColor: theme.color.rgba(theme.color.white, 0.1),
                        zIndex: 10,
                        paddingHorizontal: theme.spacing.bigger,
                        height: 44,
                    }}
                >
                    <Text smallest uppercase semiBold>
                        Asset distribution
                    </Text>

                    <Select
                        options={options}
                        selected={selected}
                        onChange={setSelected}
                    />
                </Flex>

                <Flex style={{ padding: theme.spacing.bigger, flex: 1 }}>
                    <Box flex={1} gap={theme.spacing.medium}>
                        {metadata.map((item, index) => {
                            return (
                                <Box
                                    key={`asset-class-${item.id}`}
                                    gap={theme.spacing.tiny}
                                >
                                    <Text
                                        smaller
                                        color={theme.color.rgba(
                                            theme.color.white,
                                            0.6
                                        )}
                                    >
                                        {item.label === AssetClass.Commodity
                                            ? "Commodities"
                                            : item.label}
                                    </Text>
                                    <Text
                                        medium
                                        semiBold
                                        color={theme.color.rgba(
                                            item.total === 0
                                                ? theme.color.white
                                                : item.color,
                                            item.total ? item.total : 0.4
                                        )}
                                    >
                                        {item.total
                                            ? formatLargeNumber(item.total)
                                            : "None"}
                                    </Text>
                                </Box>
                            );
                        })}
                    </Box>

                    <Box
                        flex={1}
                        justify="center"
                        align="center"
                        style={{
                            paddingVertical: theme.spacing.big,
                        }}
                    >
                        {metadata.length ? (
                            <Doughnut
                                labels={metadata?.map((coin, index) =>
                                    coin.label === AssetClass.Commodity
                                        ? "Commodities"
                                        : coin.label
                                )}
                                // @ts-ignore
                                datasets={datasets}
                            />
                        ) : (
                            <Box flex={1} align="center" justify="center">
                                <Text
                                    smallest
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        0.6
                                    )}
                                >
                                    No data
                                </Text>
                            </Box>
                        )}
                    </Box>
                </Flex>
            </Box>
        );
    }
);

export const GET_USER_GROUP = gql`
    query getUserGroupStatistics($user: String) @api(name: subgraph) {
        userGroupStats(where: { user: $user }) {
            totalPnL
            totalClosedCollateral
            totalOpenTrades
            totalTrades
            totalMarketOrders
            totalOpenLimitOrders
            totalClosedVolume
            totalOpenVolume
            totalCancelledOrders
            id
            totalVolume
            totalProfitTrades
            totalLossTrades
            totalPnL
            group {
                id
                name
            }
        }
    }
`;
