import { Box, Flex } from "@atoms/Flex";
import { Image } from "@atoms/Image";
import { Text } from "@atoms/Text";
import { LineStatistic } from "@molecules/Statistic";
import { Tooltip } from "@molecules/Tooltip";
import {
    capitalizeFirstLetter,
    formatDate,
    formatPrice,
    getPairLogo,
} from "@utils";
import { Maybe, Pair } from "gql/graphql";
import { memo, useMemo, useState } from "react";
import { Pressable, ViewStyle } from "react-native";
import { useTheme } from "styled-components/native";
import { formatUnits } from "viem";
import { ListItemCell } from "..";
import { IListColumn } from "../Column";
import { Skeleton } from "@molecules/Skeleton";
import { Lottie } from "@atoms/Lottie/Lottie";

import GOLD_MEDAL from "../../../../../../public/assets/animations/medal-gold.json";
import SILVER_MEDAL from "../../../../../../public/assets/animations/medal-silver.json";
import BRONZE_MEDAL from "../../../../../../public/assets/animations/medal-bronze.json";

export const Rank = ({
    rank,
    hasMedals,
}: {
    rank?: number;
    hasMedals?: boolean;
}) => {
    // const medal =
    //     rank === 1 ? "🥇" : rank === 2 ? "🥈" : rank === 3 ? "🥉" : "";
    const medal =
        rank === 1
            ? GOLD_MEDAL
            : rank === 2
            ? SILVER_MEDAL
            : rank === 3
            ? BRONZE_MEDAL
            : null;

    if (hasMedals && medal)
        return (
            <Box
                style={{
                    width: 24,
                    height: 24,
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    borderRadius: 100,
                }}
            >
                <Lottie source={medal} width={24} height={24} />
            </Box>
        );

    // <Text bigger>{medal}</Text>
    return <Text smaller>#{rank}</Text>;
};

export const PairItem = memo(
    ({
        from,
        to,
        isBuy,
        label,
        value,
        leverage,
        isLoading,
        pair,
        big,
        isLeverageInt = false,
    }: {
        pair?: Pair;
        from?: string;
        to?: string;
        label?: string;
        value?: string;
        isBuy?: Maybe<boolean>;
        leverage?: bigint;
        isLoading?: boolean;
        big?: boolean;
        isLeverageInt?: boolean;
    }) => {
        const theme = useTheme();

        const pairFrom = from ?? pair?.from;
        const pairTo = to ?? pair?.to;

        const image = useMemo(
            () => getPairLogo(pairFrom as string, pairTo),
            [pairFrom, pairTo]
        );

        return (
            <Skeleton
                isLoading={!!isLoading}
                style={{
                    flex: 1,
                    height: "100%",
                    position: "relative",
                }}
                image
                secondary={{ width: 40 }}
                width={50}
            >
                <Flex
                    align="center"
                    gap={big ? theme.spacing.medium : theme.spacing.small}
                >
                    {pairFrom ? (
                        <Image
                            lazy
                            source={image}
                            selectable={false}
                            alt={`${pairFrom}-logo`}
                            width={big ? 32 : 24}
                            height={big ? 32 : 24}
                            quality={100}
                        />
                    ) : null}

                    <Box flex={1} gap={theme.spacing.tiny}>
                        <Text semiBold smaller={!big} small={big} uppercase>
                            {label ?? `${pairFrom}/${pairTo}`}
                        </Text>

                        {value ? (
                            <Text
                                smallest
                                color={theme.color.rgba(theme.color.white, 0.4)}
                            >
                                {value}
                            </Text>
                        ) : (
                            <>
                                {leverage &&
                                Number(formatUnits(leverage, 2)) !== 0 &&
                                isBuy != null ? (
                                    <Text smallest red={!isBuy} green={isBuy}>
                                        {isBuy ? "Long" : "Short"}{" "}
                                        {formatPrice(
                                            isLeverageInt
                                                ? Number(leverage)
                                                : Number(
                                                      formatUnits(leverage, 2)
                                                  ),
                                            {
                                                decimals: 1,
                                            }
                                        )}
                                        x
                                    </Text>
                                ) : null}
                            </>
                        )}
                    </Box>
                </Flex>
            </Skeleton>
        );
    }
);

export const ClosePrice = ({
    column,
    isFirst,
    isLast,
    onPress,
    stopLoss,
    takeProfit,
    liquidationPrice,
}: {
    column: IListColumn;
    isFirst: boolean;
    isLast: boolean;
    takeProfit: number;
    stopLoss?: number;
    liquidationPrice?: number;
    onPress?: () => void;
}) => {
    const theme = useTheme();

    return (
        <ListItemCell
            isFirst={isFirst}
            isLast={isLast}
            onPress={onPress}
            text={`TP: ${formatPrice(takeProfit, {
                significant: 6,
            })}`}
            textProps={{
                color: theme.color.green,
            }}
            secondaryText={
                stopLoss
                    ? `SL: ${formatPrice(stopLoss, {
                          significant: 6,
                      })}`
                    : liquidationPrice
                    ? `LIQ: ${formatPrice(liquidationPrice, {
                          significant: 6,
                      })}`
                    : "SL: None"
            }
            secondaryTextProps={{
                color:
                    stopLoss || liquidationPrice
                        ? theme.color.red
                        : theme.color.rgba(theme.color.white, 0.4),
            }}
            tooltip={{
                title: "Close Price",
                pointerEvents: "none",
                minWidth: 200,
                renderContent: (
                    <Box
                        gap={theme.spacing.smallest}
                        style={{
                            marginTop: theme.spacing.small,
                        }}
                    >
                        <LineStatistic
                            label="Take Profit"
                            color={
                                !!takeProfit
                                    ? theme.color.green
                                    : theme.color.rgba(theme.color.white, 0.4)
                            }
                            value={formatPrice(takeProfit, {
                                significant: 6,
                            })}
                        />

                        <LineStatistic
                            label="Stop Loss"
                            color={
                                stopLoss != null && stopLoss > 0
                                    ? theme.color.red
                                    : theme.color.rgba(theme.color.white, 0.4)
                            }
                            value={
                                stopLoss
                                    ? formatPrice(stopLoss, {
                                          significant: 6,
                                      })
                                    : "None"
                            }
                        />
                        {liquidationPrice ? (
                            <LineStatistic
                                label="Liquidation Price"
                                color={
                                    !stopLoss
                                        ? theme.color.red
                                        : theme.color.rgba(
                                              theme.color.white,
                                              0.4
                                          )
                                }
                                value={
                                    !stopLoss
                                        ? formatPrice(liquidationPrice, {
                                              significant: 6,
                                          })
                                        : "None"
                                }
                            />
                        ) : null}
                    </Box>
                ),
            }}
            style={column.style}
        />
    );
};

export const PositionSizeCollateral = ({
    size,
    collateral,
    pair,
    style,
    isLoading,
}: {
    size?: number;
    collateral?: number;
    pair?: Pair | null;
    isLoading?: boolean;
    style?: ViewStyle;
}) => {
    const theme = useTheme();

    return (
        <Skeleton
            isLoading={!!isLoading}
            style={{
                flex: 1,
                height: "100%",
                justifyContent: "center",
                position: "relative",
            }}
            secondary={{ width: 70 }}
            width={80}
        >
            <Box style={style} gap={theme.spacing.tiny}>
                <Flex align="center" gap={theme.spacing.tiny}>
                    <Text mono smaller semiBold>
                        {size
                            ? `${formatPrice(size, {
                                  currency: false,
                                  significant: 6,
                              })} ${pair?.from}`
                            : ""}
                    </Text>
                    {/* <Pill text={pair?.from} /> */}
                </Flex>
                <Text
                    mono
                    smaller
                    color={theme.color.rgba(theme.color.white, 0.4)}
                >
                    {collateral
                        ? `${formatPrice(collateral, {
                              decimals: 2,
                          })}`
                        : ""}
                </Text>
            </Box>
        </Skeleton>
    );
};

export const TakeProfitStopLoss = ({
    takeProfit,
    stopLoss,
    isLoading,
    onToggle,
}: {
    takeProfit: number;
    stopLoss: number;
    isLoading?: boolean;
    onToggle?: () => void;
}) => {
    const theme = useTheme();

    return (
        <Skeleton
            isLoading={!!!isLoading}
            style={{
                flex: 1,
                height: "100%",
                justifyContent: "center",
                position: "relative",
            }}
            secondary={{ width: 70 }}
            width={80}
        >
            <Box justify="center" gap={theme.spacing.smallest}>
                <Text
                    mono
                    smaller
                    color={
                        !!takeProfit
                            ? theme.color.green
                            : theme.color.rgba(theme.color.white, 0.4)
                    }
                >
                    {takeProfit
                        ? `TP: ${formatPrice(takeProfit, {
                              significant: 6,
                          })}`
                        : "TP: None"}
                </Text>

                <Text
                    mono
                    smaller
                    color={
                        stopLoss > 0
                            ? theme.color.red
                            : theme.color.rgba(theme.color.white, 0.4)
                    }
                >
                    {stopLoss > 0
                        ? `SL: ${formatPrice(stopLoss, {
                              significant: 6,
                          })}`
                        : "SL: None"}
                </Text>
            </Box>
            {/* <Tooltip
             position="top-center"
             left={-4}
             pointerEvents="none"
             tiny
             renderContent={
                 <Text
                     smallest
                     color={rgba(theme.color.white, 0.7)}
                     style={{ whiteSpace: "nowrap" }}
                 >
                     Edit
                 </Text>
             }
             style={{
                 minWidth: MIN_WIDTH,
                 maxWidth: MAX_WIDTH,
                 justifyContent: "center",
                 height: "100%",
             }}
         >
         <ActionButton
             onPress={() => onToggle?.()}
             style={{
                 justifyContent: "center",
             }}
         > *
         ...rest
        </ActionButton>
        </Tooltip> */}
        </Skeleton>
    );
};

export const PositionPNL = ({
    pnl,
    rollover,
    funding,
    netPNL,
    percent,
    isLoading,
    style,
    noTooltip,
}: {
    pnl: number;
    percent: number;
    collateral: number;
    isBuy: boolean;
    rollover?: number;
    funding?: number;
    netPNL: number;
    netValue?: number;
    isLoading?: boolean;
    style?: ViewStyle;
    noTooltip?: boolean;
}) => {
    const theme = useTheme();

    const renderContent = useMemo(() => {
        return (
            <Box
                justify="center"
                align="flex-end"
                style={{ height: "100%" }}
                gap={theme.spacing.smallest}
            >
                <Text mono bold red={netPNL < 0} green={netPNL > 0}>
                    {`${netPNL > 0 ? "+" : ""}${formatPrice(netPNL, {
                        decimals: 2,
                    })}`}
                </Text>

                <Text smallest mono red={percent < 0} green={percent > 0}>
                    {`${percent > 0 ? "+" : ""}${formatPrice(percent, {
                        decimals: 2,
                        currency: false,
                    })}`}
                    %
                </Text>
            </Box>
        );
    }, [netPNL, percent, theme.spacing.smallest]);

    return (
        <Skeleton
            isLoading={!!isLoading}
            style={{
                justifyContent: "center",
                alignItems: "flex-end",
                height: "100%",
            }}
            secondary={{ width: 40 }}
            width={110}
        >
            {noTooltip ? (
                renderContent
            ) : (
                <Tooltip
                    title="Profit And Loss Breakdown"
                    position="top-right"
                    minWidth
                    pointerEvents="none"
                    style={{
                        flex: 1,
                        height: "100%",
                        ...style,
                    }}
                    renderContent={
                        <Box style={{ marginTop: theme.spacing.big }}>
                            <Box gap={theme.spacing.smaller}>
                                <LineStatistic
                                    label="PNL Price Change"
                                    value={`${pnl > 0 ? "+" : ""}${formatPrice(
                                        pnl,
                                        {
                                            significant: 6,
                                        }
                                    )}`}
                                    green={pnl > 0}
                                    red={pnl < 0}
                                />

                                {rollover != null ? (
                                    <LineStatistic
                                        label="Rollover Fee"
                                        red={rollover != 0}
                                        value={formatPrice(-rollover, {
                                            decimals: rollover ? 2 : 0,
                                        })}
                                    />
                                ) : null}

                                {funding != null ? (
                                    <LineStatistic
                                        label="Funding Fee"
                                        red={funding > 0}
                                        green={funding < 0}
                                        value={`${
                                            funding > 0
                                                ? "-"
                                                : funding < 0
                                                ? "+"
                                                : ""
                                        }${formatPrice(Math.abs(funding), {
                                            decimals: funding ? 2 : 0,
                                        })}`}
                                    />
                                ) : null}
                            </Box>
                            <Box
                                style={{
                                    borderTopWidth: 1,
                                    borderColor: theme.color.rgba(
                                        theme.color.white,
                                        0.1
                                    ),
                                    paddingTop: theme.spacing.smaller,
                                    marginTop: theme.spacing.small,
                                }}
                            >
                                {netPNL != null ? (
                                    <LineStatistic
                                        label="Net PNL"
                                        red={netPNL < 0}
                                        green={netPNL > 0}
                                        value={formatPrice(netPNL, {
                                            significant: 6,
                                        })}
                                    />
                                ) : null}
                            </Box>
                        </Box>
                    }
                >
                    {renderContent}
                </Tooltip>
            )}
        </Skeleton>
    );
};

export const Time = ({ date, time }: { date: Date; time: Date }) => {
    const theme = useTheme();
    return (
        <Box gap={theme.spacing.smallest} justify="center" flex={1}>
            <Text tiny mono>
                {formatDate(date, {
                    month: "2-digit",
                    day: "2-digit",
                })}
            </Text>

            <Text tiny mono color={theme.color.rgba(theme.color.white, 0.4)}>
                {formatDate(time, {
                    timeStyle: "short",
                })}
            </Text>
        </Box>
    );
};

export const Operation = ({
    action,
    type,
}: {
    action: string;
    type: string;
}) => {
    const theme = useTheme();
    return (
        <Box gap={theme.spacing.smallest}>
            <Text smaller semiBold>
                {action}
            </Text>

            <Text smaller color={theme.color.rgba(theme.color.white, 0.4)}>
                {capitalizeFirstLetter(type.toLowerCase())} Order
            </Text>
        </Box>
    );
};

export const ActionButton = ({
    children,
    style,
    onPress,
}: {
    children: JSX.Element | JSX.Element[];
    style: ViewStyle;
    onPress: () => void;
}) => {
    const theme = useTheme();
    const [isHovering, setIsHovering] = useState(false);

    return (
        <Pressable onPress={onPress} style={style}>
            <Flex
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                gap={theme.spacing.small}
                align="center"
                style={{
                    flex: 1,
                    backgroundColor: theme.color.rgba(
                        theme.color.white,
                        isHovering ? 0.05 : 0
                    ),
                    borderWidth: 1,
                    borderColor: theme.color.rgba(
                        theme.color.white,
                        isHovering ? 0.1 : 0
                    ),
                    borderRadius: theme.radius.small,
                    padding: theme.spacing.smallest,
                    margin: -theme.spacing.smallest,
                    marginRight: theme.spacing.small,
                }}
            >
                <Box>{children}</Box>
                {/* <Button tiny stroke text="edit" /> */}
                {/* <Box>
                    <Icon
                        name="edit"
                        size={14}
                        color={rgba(theme.color.white, isHovering ? 1 : 0.4)}
                    />
                </Box> */}
            </Flex>
        </Pressable>
    );
};
