import { Box, Flex } from "@atoms/Flex";
import { Icon, IconInterface } from "@atoms/Icon";
import { IText, Text } from "@atoms/Text";
import { Skeleton } from "@molecules/Skeleton";
import { Pill } from "@screens/Trade/components/Pill";
import { memo, useMemo } from "react";
import { ViewStyle } from "react-native";
import { useTheme } from "styled-components/native";
import { ITooltip, Tooltip } from "../Tooltip";
import { Image, ImageInterface } from "@atoms/Image";

export interface StatisticProps {
    tooltip?: ITooltip;
    label?: string | JSX.Element;
    value?: string | number;
    secondaryValue?: string | number | null;
    green?: boolean;
    right?: boolean;
    red?: boolean;
    noIcon?: boolean;
    small?: boolean;
    big?: boolean;
    selectable?: boolean;
    loading?: boolean;
    style?: ViewStyle;
    renderExtraLabel?: JSX.Element;
    children?: JSX.Element;
    mono?: boolean;
    direction?: "row" | "row-reverse";
    iconProps?: IconInterface;
    imageProps?: ImageInterface;
    valueProps?: IText;
    secondaryValueProps?: IText;
    iconOpacity?: number;
}

export interface LineStatisticProps {
    loading?: boolean;
    label: string;
    value?: string | number;
    valueExtra?: string;
    red?: boolean;
    big?: boolean;
    green?: boolean;
    redExtra?: boolean;
    greenExtra?: boolean;
    small?: boolean;
    primary?: boolean;
    renderLabelExtra?: JSX.Element | JSX.Element[];
    renderExtra?: JSX.Element | JSX.Element[];
    tooltip?: ITooltip;
    style?: ViewStyle;
    color?: string;
    noIcon?: boolean;
    mono?: boolean;
    valueProps?: IText;
    iconProps?: IconInterface;
    imageProps?: ImageInterface;
    children?: JSX.Element | null;
}

export const Statistic = memo(
    ({
        label,
        value,
        valueProps,
        green,
        red,
        small,
        big,
        right,
        tooltip,
        renderExtraLabel,
        selectable,
        direction,
        mono = true,
        noIcon,
        iconProps,
        imageProps,
        iconOpacity = 0.6,
        loading = false,
        children,
        style,
        secondaryValue,
        secondaryValueProps,
    }: StatisticProps) => {
        const theme = useTheme();

        const renderContent = useMemo(() => {
            return (
                <Flex gap={theme.spacing.smaller} align="center">
                    {iconProps ? <Icon {...iconProps} /> : null}
                    {imageProps ? (
                        <Image
                            lazy
                            alt={"image"}
                            width={16}
                            // @ts-ignore
                            height={16}
                            selectable={false}
                            {...imageProps}
                        />
                    ) : null}
                    <Skeleton
                        width={90}
                        height={big ? 16 : 12}
                        isLoading={
                            (value === undefined && !children) || !!loading
                        }
                    >
                        {children || (
                            <Flex gap={theme.spacing.tiny}>
                                <Text
                                    smaller={small && !big}
                                    big={big && !small}
                                    small={!big && !small}
                                    mono={mono}
                                    bold
                                    red={red}
                                    green={green}
                                    selectable={selectable}
                                    {...valueProps}
                                >
                                    {value}
                                </Text>
                                {secondaryValue ? (
                                    <Text
                                        smaller={small && !big}
                                        big={big && !small}
                                        small={!big && !small}
                                        mono={mono}
                                        red={red}
                                        green={green}
                                        selectable={selectable}
                                        {...valueProps}
                                        {...secondaryValueProps}
                                        color={secondaryValueProps?.color ?? theme.color.rgba(
                                            theme.color.white,
                                            0.6
                                        )}
                                    >
                                        {secondaryValue}
                                    </Text>
                                ) : null}
                            </Flex>
                        )}
                    </Skeleton>
                </Flex>
            );
        }, [
            theme.spacing.smaller,
            theme.spacing.tiny,
            theme.color,
            iconProps,
            imageProps,
            big,
            value,
            children,
            loading,
            small,
            mono,
            red,
            green,
            selectable,
            valueProps,
            secondaryValue,
            secondaryValueProps,
        ]);

        if (tooltip)
            return (
                <Tooltip {...tooltip}>
                    <Box
                        style={[
                            style,
                            { alignItems: right ? "flex-end" : "flex-start" },
                        ]}
                        gap={theme.spacing.smaller}
                    >
                        <Flex
                            align="center"
                            direction={direction || undefined}
                            gap={theme.spacing.smallest}
                        >
                            <Text
                                smaller={!big}
                                selectable={selectable}
                                color={theme.color.rgba(theme.color.white, 0.6)}
                            >
                                {label}
                            </Text>

                            {renderExtraLabel}

                            {noIcon ? null : (
                                <Icon
                                    name="question"
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        iconOpacity
                                    )}
                                    size={10}
                                />
                            )}
                        </Flex>

                        {renderContent}
                    </Box>
                </Tooltip>
            );

        return (
            <Box
                style={[
                    style,
                    { alignItems: right ? "flex-end" : "flex-start" },
                ]}
                gap={theme.spacing.small}
            >
                <Flex
                    align="center"
                    gap={big ? theme.spacing.smaller : theme.spacing.smallest}
                    direction={direction || undefined}
                >
                    <Text
                        smaller={!big}
                        selectable={selectable}
                        color={theme.color.rgba(theme.color.white, 0.6)}
                    >
                        {label}
                    </Text>

                    {renderExtraLabel}
                </Flex>

                {renderContent}
            </Box>
        );
    }
);

export const LineStatistic = memo(
    ({
        label,
        value,
        valueExtra,
        primary,
        red,
        redExtra,
        greenExtra,
        green,
        small,
        big,
        renderExtra,
        renderLabelExtra,
        tooltip,
        style,
        color,
        noIcon,
        valueProps,
        mono = true,
        children,
        loading,
    }: LineStatisticProps) => {
        const theme = useTheme();

        return (
            <Flex
                justify="space-between"
                align="center"
                style={style}
                gap={theme.spacing.tiny}
            >
                <Flex align="center" gap={theme.spacing.tiny}>
                    <Text
                        smaller={!small && !big}
                        smallest={small && !big}
                        numberOfLines={1}
                        lineHeight={theme.text.medium}
                        color={theme.color.rgba(theme.color.white, 0.7)}
                    >
                        {label}
                    </Text>
                    {tooltip ? (
                        noIcon ? null : (
                            <Tooltip {...tooltip}>
                                <Box
                                    style={{ height: 13, width: 13 }}
                                    align="center"
                                    justify="center"
                                >
                                    <Icon
                                        name="question"
                                        color={theme.color.rgba(
                                            theme.color.white,
                                            0.6
                                        )}
                                        size={10}
                                    />
                                </Box>
                            </Tooltip>
                        )
                    ) : null}
                    {renderLabelExtra}
                </Flex>

                <Skeleton
                    width={90}
                    height={12}
                    isLoading={(value === undefined && !children) || !!loading}
                >
                    <Flex align="center" flex={1} justify="flex-end">
                        {children || (
                            <Text
                                smaller={!small && !big}
                                smallest={small && !big}
                                red={red}
                                primary={primary}
                                mono={mono}
                                color={color}
                                numberOfLines={1}
                                green={green}
                                {...valueProps}
                            >{`${value}`}</Text>
                        )}

                        {renderExtra ? (
                            renderExtra
                        ) : valueExtra ? (
                            <Box
                                style={{
                                    paddingLeft: theme.spacing.tiny,
                                }}
                            >
                                <Pill
                                    text={valueExtra}
                                    green={greenExtra}
                                    red={redExtra}
                                />
                            </Box>
                        ) : null}
                    </Flex>
                </Skeleton>
            </Flex>
        );
    }
);
